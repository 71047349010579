import {TeamDetailModel} from './TeamDetailModel';
import {SubTeamDetailModel} from './SubTeamDetailModel';
import {UniversityModel} from './UniversityModel';
import {IndustryModel} from './IndustryModel';
import {DegreePoolModel} from './DegreePoolModel';
import {VariableModel} from './VariableModel';
import {EmailTemplateModel} from './EmailTemplateModel';
import {AchievementModel} from './AchievementModel';
import {TaskModel} from './TaskModel';
import {ActionCategoriesModel} from './ActionCategoriesModel';
import {SeasonPlatformSettingsModel} from './SeasonPlatformSettingsModel';
import {ScorecardDateSettingsModel} from './ScoreCardDateSettingsModel';
import {SeasonModel} from './SeasonModel';
import {LearningContractConfigModel} from './LearningContractConfigModel';
import {GraduatingCapabilitiesModel} from './GraduatingCapabilitiesModel';
import {CompletionSourceConfigModel} from './CompletionSourceConfigModel';
import {GenderPronounsConfigModel} from './GenderPronounsConfigModel';
import {AtRiskReasonConfigModel} from './AtRiskReasonConfigModel';
import {CareerQuestionnaireConfigModel} from './CareerQuestionnaireConfigModel';
import { InactiveReasonConfigModel } from './InactiveReasonConfigModel';
import {AddressViewModel} from './AddressViewModel';
import {InternshipExperienceFieldsModel} from './InternshipExperienceFieldsModel';
import {ClientStatus, IcsStatus, PartnerStatus, PoolStatus, PoolStudentStatus, StudentStatusEnum, WorkShadowStatusEnum} from './Enums';

export class PlatformSettingsModel implements IPlatformSettingsModel {
  isMaintenance?: boolean | undefined;
  /** Tags that can be applied to students */
  studentTags?: string[] | undefined;
  /** Tags that can be applied to client companies */
  contactTags?: string[] | undefined;
  /** Tags that can be applied to client contacts */
  clientTags?: string[] | undefined;
  /** Tags that can be applied to partner companies */
  partnerTags?: string[] | undefined;
  /** Tags that can be applied to education companies */
  educationTags?: string[] | undefined;
  /** Tags that can be applied to volunteer companies */
  volunteerTags?: string[] | undefined;
  /** Tags that can be applied to work shadow students */
  workShadowTags?: string[] | undefined;
  /** Tags that can be applied to alumni students */
  alumniTags?: string[] | undefined;
  teams?: TeamDetailModel[] | undefined;
  subTeams?: SubTeamDetailModel[] | undefined;
  /** All Australian universities */
  universities?: UniversityModel[] | undefined;
  /** All industries and sub-industries */
  industries?: IndustryModel[] | undefined;
  /** Types of degrees */
  degreePools?: DegreePoolModel[] | undefined;
  /** Student status values */
  studentStatus?: StudentStatusEnum[] | undefined;
  /** Student status values */
  workShadowStatus?: WorkShadowStatusEnum[] | undefined;
  /** Student ICS status values */
  icsStatus?: IcsStatus[] | undefined;
  /** Client status values */
  clientStatus?: ClientStatus[] | undefined;
  /** Partner status values */
  partnerStatus?: PartnerStatus[] | undefined;
  /** Company pool status values */
  poolStatus?: PoolStatus[] | undefined;
  /** Status of students on pools */
  poolStudentStatus?: PoolStudentStatus[] | undefined;
  /** All email template substitution variables for settings */
  emailTemplateVariables?: VariableModel[] | undefined;
  /** All email templates */
  emailTemplates?: EmailTemplateModel[] | undefined;
  companyTypes?: string[] | undefined;
  partnerTypes?: string[] | undefined;
  institutionTypes?: string[] | undefined;
  achievements?: AchievementModel[] | undefined;
  studentTaskActions?: TaskModel[] | undefined;
  highSchoolProgramTaskActions?: TaskModel[] | undefined;
  placementTaskActions?: TaskModel[] | undefined;
  internshipExperienceFields?: InternshipExperienceFieldsModel[] | undefined;
  actionCategories?: ActionCategoriesModel | undefined;
  currentSeasonSettings?: SeasonPlatformSettingsModel | undefined;
  approachingSeasonSettings?: SeasonPlatformSettingsModel | undefined;
  scorecardDateSeasonSettings?: ScorecardDateSettingsModel | undefined;
  semesterDateSeasonSettings?: SeasonModel | undefined;
  learningContractConfig?: LearningContractConfigModel[] | undefined;
  graduatingCapabilitiesConfig?: GraduatingCapabilitiesModel[] | undefined;
  preUniversityGraduatingCapabilitiesConfig?: GraduatingCapabilitiesModel[] | undefined;
  careerQuestionnaireConfig?: CareerQuestionnaireConfigModel[] | undefined;
  completionSourceConfig?: CompletionSourceConfigModel | undefined;
  genderPronounsConfig?: GenderPronounsConfigModel[] | undefined;
  atRiskReasonConfigs?: AtRiskReasonConfigModel[] | undefined;
  inactiveReasonConfigs?: InactiveReasonConfigModel[] | undefined;
  studentTravelLocationsConfig?: AddressViewModel[] | undefined;
  companyTaskActions?: TaskModel[] | undefined;

  constructor(data?: IPlatformSettingsModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.isMaintenance = Data["isMaintenance"];
      if (Array.isArray(Data["studentTags"])) {
        this.studentTags = [] as any;
        for (let item of Data["studentTags"])
          this.studentTags!.push(item);
      }
      if (Array.isArray(Data["contactTags"])) {
        this.contactTags = [] as any;
        for (let item of Data["contactTags"])
          this.contactTags!.push(item);
      }
      if (Array.isArray(Data["clientTags"])) {
        this.clientTags = [] as any;
        for (let item of Data["clientTags"])
          this.clientTags!.push(item);
      }
      if (Array.isArray(Data["partnerTags"])) {
        this.partnerTags = [] as any;
        for (let item of Data["partnerTags"])
          this.partnerTags!.push(item);
      }
      if (Array.isArray(Data["educationTags"])) {
        this.educationTags = [] as any;
        for (let item of Data["educationTags"])
          this.educationTags!.push(item);
      }
      if (Array.isArray(Data["volunteerTags"])) {
        this.volunteerTags = [] as any;
        for (let item of Data["volunteerTags"])
          this.volunteerTags!.push(item);
      }
      if (Array.isArray(Data["workShadowTags"])) {
        this.workShadowTags = [] as any;
        for (let item of Data["workShadowTags"])
          this.workShadowTags!.push(item);
      }
      if (Array.isArray(Data["alumniTags"])) {
        this.alumniTags = [] as any;
        for (let item of Data["alumniTags"])
          this.alumniTags!.push(item);
      }
      if (Array.isArray(Data["teams"])) {
        this.teams = [] as any;
        for (let item of Data["teams"])
          this.teams!.push(TeamDetailModel.fromJS(item));
      }
      if (Array.isArray(Data["subTeams"])) {
        this.subTeams = [] as any;
        for (let item of Data["subTeams"])
          this.subTeams!.push(SubTeamDetailModel.fromJS(item));
      }
      if (Array.isArray(Data["universities"])) {
        this.universities = [] as any;
        for (let item of Data["universities"])
          this.universities!.push(UniversityModel.fromJS(item));
      }
      if (Array.isArray(Data["industries"])) {
        this.industries = [] as any;
        for (let item of Data["industries"])
          this.industries!.push(IndustryModel.fromJS(item));
      }
      if (Array.isArray(Data["degreePools"])) {
        this.degreePools = [] as any;
        for (let item of Data["degreePools"])
          this.degreePools!.push(DegreePoolModel.fromJS(item));
      }
      if (Array.isArray(Data["studentStatus"])) {
        this.studentStatus = [] as any;
        for (let item of Data["studentStatus"])
          this.studentStatus!.push(item);
      }
      if (Array.isArray(Data["workShadowStatus"])) {
        this.workShadowStatus = [] as any;
        for (let item of Data["workShadowStatus"])
          this.workShadowStatus!.push(item);
      }
      if (Array.isArray(Data["icsStatus"])) {
        this.icsStatus = [] as any;
        for (let item of Data["icsStatus"])
          this.icsStatus!.push(item);
      }
      if (Array.isArray(Data["clientStatus"])) {
        this.clientStatus = [] as any;
        for (let item of Data["clientStatus"])
          this.clientStatus!.push(item);
      }
      if (Array.isArray(Data["partnerStatus"])) {
        this.partnerStatus = [] as any;
        for (let item of Data["partnerStatus"])
          this.partnerStatus!.push(item);
      }
      if (Array.isArray(Data["poolStatus"])) {
        this.poolStatus = [] as any;
        for (let item of Data["poolStatus"])
          this.poolStatus!.push(item);
      }
      if (Array.isArray(Data["poolStudentStatus"])) {
        this.poolStudentStatus = [] as any;
        for (let item of Data["poolStudentStatus"])
          this.poolStudentStatus!.push(item);
      }
      if (Array.isArray(Data["emailTemplateVariables"])) {
        this.emailTemplateVariables = [] as any;
        for (let item of Data["emailTemplateVariables"])
          this.emailTemplateVariables!.push(VariableModel.fromJS(item));
      }
      if (Array.isArray(Data["emailTemplates"])) {
        this.emailTemplates = [] as any;
        for (let item of Data["emailTemplates"])
          this.emailTemplates!.push(EmailTemplateModel.fromJS(item));
      }
      if (Array.isArray(Data["companyTypes"])) {
        this.companyTypes = [] as any;
        for (let item of Data["companyTypes"])
          this.companyTypes!.push(item);
      }
      if (Array.isArray(Data["partnerTypes"])) {
        this.partnerTypes = [] as any;
        for (let item of Data["partnerTypes"])
          this.partnerTypes!.push(item);
      }
      if (Array.isArray(Data["institutionTypes"])) {
        this.institutionTypes = [] as any;
        for (let item of Data["institutionTypes"])
          this.institutionTypes!.push(item);
      }
      if (Array.isArray(Data["achievements"])) {
        this.achievements = [] as any;
        for (let item of Data["achievements"])
          this.achievements!.push(AchievementModel.fromJS(item));
      }
      if (Array.isArray(Data["studentTaskActions"])) {
        this.studentTaskActions = [] as any;
        for (let item of Data["studentTaskActions"])
          this.studentTaskActions!.push(TaskModel.fromJS(item));
      }
      if (Array.isArray(Data["highSchoolProgramTaskActions"])) {
        this.highSchoolProgramTaskActions = [] as any;
        for (let item of Data["highSchoolProgramTaskActions"])
          this.highSchoolProgramTaskActions!.push(TaskModel.fromJS(item));
      }
      if (Array.isArray(Data["placementTaskActions"])) {
        this.placementTaskActions = [] as any;
        for (let item of Data["placementTaskActions"])
          this.placementTaskActions!.push(TaskModel.fromJS(item));
      }
      if (Array.isArray(Data["internshipExperienceFields"])) {
        this.internshipExperienceFields = [] as any;
        for (let item of Data["internshipExperienceFields"])
          this.internshipExperienceFields!.push(InternshipExperienceFieldsModel.fromJS(item));
      }
      this.actionCategories = Data["actionCategories"] ? ActionCategoriesModel.fromJS(Data["actionCategories"]) : <any>undefined;
      this.currentSeasonSettings = Data["currentSeasonSettings"] ? SeasonPlatformSettingsModel.fromJS(Data["currentSeasonSettings"]) : <any>undefined;
      this.approachingSeasonSettings = Data["approachingSeasonSettings"] ? SeasonPlatformSettingsModel.fromJS(Data["approachingSeasonSettings"]) : <any>undefined;
      this.scorecardDateSeasonSettings = Data["scorecardDateSeasonSettings"] ? ScorecardDateSettingsModel.fromJS(Data["scorecardDateSeasonSettings"]) : <any>undefined;
      this.semesterDateSeasonSettings = Data["semesterDateSeasonSettings"] ? SeasonModel.fromJS(Data["semesterDateSeasonSettings"]) : <any>undefined;
      if (Array.isArray(Data["learningContractConfig"])) {
        this.learningContractConfig = [] as any;
        for (let item of Data["learningContractConfig"])
          this.learningContractConfig!.push(LearningContractConfigModel.fromJS(item));
      }
      if (Array.isArray(Data["graduatingCapabilitiesConfig"])) {
        this.graduatingCapabilitiesConfig = [] as any;
        for (let item of Data["graduatingCapabilitiesConfig"])
          this.graduatingCapabilitiesConfig!.push(GraduatingCapabilitiesModel.fromJS(item));
      }
      if (Array.isArray(Data["preUniversityGraduatingCapabilitiesConfig"])) {
        this.preUniversityGraduatingCapabilitiesConfig = [] as any;
        for (let item of Data["preUniversityGraduatingCapabilitiesConfig"])
          this.preUniversityGraduatingCapabilitiesConfig!.push(GraduatingCapabilitiesModel.fromJS(item));
      }
      if (Array.isArray(Data["careerQuestionnaireConfig"])) {
        this.careerQuestionnaireConfig = [] as any;
        for (let item of Data["careerQuestionnaireConfig"])
          this.careerQuestionnaireConfig!.push(CareerQuestionnaireConfigModel.fromJS(item));
      }
      this.completionSourceConfig = Data["completionSourceConfig"] ? CompletionSourceConfigModel.fromJS(Data["completionSourceConfig"]) : <any>undefined;
      if (Array.isArray(Data["genderPronounsConfig"])) {
        this.genderPronounsConfig = [] as any;
        for (let item of Data["genderPronounsConfig"])
          this.genderPronounsConfig!.push(GenderPronounsConfigModel.fromJS(item));
      }
      if (Array.isArray(Data["atRiskReasonConfigs"])) {
        this.atRiskReasonConfigs = [] as any;
        for (let item of Data["atRiskReasonConfigs"])
          this.atRiskReasonConfigs!.push(AtRiskReasonConfigModel.fromJS(item));
      }
      if (Array.isArray(Data["inactiveReasonConfigs"])) {
        this.inactiveReasonConfigs = [] as any;
        for (let item of Data["inactiveReasonConfigs"])
          this.inactiveReasonConfigs!.push(InactiveReasonConfigModel.fromJS(item));
      }
      if (Array.isArray(Data["studentTravelLocationsConfig"])) {
        this.studentTravelLocationsConfig = [] as any;
        for (let item of Data["studentTravelLocationsConfig"])
          this.studentTravelLocationsConfig!.push(AddressViewModel.fromJS(item));
      }
      if (Array.isArray(Data["companyTaskActions"])) {
        this.companyTaskActions = [] as any;
        for (let item of Data["companyTaskActions"])
          this.companyTaskActions!.push(TaskModel.fromJS(item));
      }
    }
  }

  static fromJS(data: any): PlatformSettingsModel {
    data = typeof data === 'object' ? data : {};
    let result = new PlatformSettingsModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["isMaintenance"] = this.isMaintenance;
    if (Array.isArray(this.studentTags)) {
      data["studentTags"] = [];
      for (let item of this.studentTags)
        data["studentTags"].push(item);
    }
    if (Array.isArray(this.contactTags)) {
      data["contactTags"] = [];
      for (let item of this.contactTags)
        data["contactTags"].push(item);
    }
    if (Array.isArray(this.clientTags)) {
      data["clientTags"] = [];
      for (let item of this.clientTags)
        data["clientTags"].push(item);
    }
    if (Array.isArray(this.partnerTags)) {
      data["partnerTags"] = [];
      for (let item of this.partnerTags)
        data["partnerTags"].push(item);
    }
    if (Array.isArray(this.educationTags)) {
      data["educationTags"] = [];
      for (let item of this.educationTags)
        data["educationTags"].push(item);
    }
    if (Array.isArray(this.volunteerTags)) {
      data["volunteerTags"] = [];
      for (let item of this.volunteerTags)
        data["volunteerTags"].push(item);
    }
    if (Array.isArray(this.workShadowTags)) {
      data["workShadowTags"] = [];
      for (let item of this.workShadowTags)
        data["workShadowTags"].push(item);
    }
    if (Array.isArray(this.alumniTags)) {
      data["alumniTags"] = [];
      for (let item of this.alumniTags)
        data["alumniTags"].push(item);
    }
    if (Array.isArray(this.teams)) {
      data["teams"] = [];
      for (let item of this.teams)
        data["teams"].push(item.toJSON());
    }
    if (Array.isArray(this.subTeams)) {
      data["subTeams"] = [];
      for (let item of this.subTeams)
        data["subTeams"].push(item.toJSON());
    }
    if (Array.isArray(this.universities)) {
      data["universities"] = [];
      for (let item of this.universities)
        data["universities"].push(item.toJSON());
    }
    if (Array.isArray(this.industries)) {
      data["industries"] = [];
      for (let item of this.industries)
        data["industries"].push(item.toJSON());
    }
    if (Array.isArray(this.degreePools)) {
      data["degreePools"] = [];
      for (let item of this.degreePools)
        data["degreePools"].push(item.toJSON());
    }
    if (Array.isArray(this.studentStatus)) {
      data["studentStatus"] = [];
      for (let item of this.studentStatus)
        data["studentStatus"].push(item);
    }
    if (Array.isArray(this.workShadowStatus)) {
      data["workShadowStatus"] = [];
      for (let item of this.workShadowStatus)
        data["workShadowStatus"].push(item);
    }
    if (Array.isArray(this.icsStatus)) {
      data["icsStatus"] = [];
      for (let item of this.icsStatus)
        data["icsStatus"].push(item);
    }
    if (Array.isArray(this.clientStatus)) {
      data["clientStatus"] = [];
      for (let item of this.clientStatus)
        data["clientStatus"].push(item);
    }
    if (Array.isArray(this.partnerStatus)) {
      data["partnerStatus"] = [];
      for (let item of this.partnerStatus)
        data["partnerStatus"].push(item);
    }
    if (Array.isArray(this.poolStatus)) {
      data["poolStatus"] = [];
      for (let item of this.poolStatus)
        data["poolStatus"].push(item);
    }
    if (Array.isArray(this.poolStudentStatus)) {
      data["poolStudentStatus"] = [];
      for (let item of this.poolStudentStatus)
        data["poolStudentStatus"].push(item);
    }
    if (Array.isArray(this.emailTemplateVariables)) {
      data["emailTemplateVariables"] = [];
      for (let item of this.emailTemplateVariables)
        data["emailTemplateVariables"].push(item.toJSON());
    }
    if (Array.isArray(this.emailTemplates)) {
      data["emailTemplates"] = [];
      for (let item of this.emailTemplates)
        data["emailTemplates"].push(item.toJSON());
    }
    if (Array.isArray(this.companyTypes)) {
      data["companyTypes"] = [];
      for (let item of this.companyTypes)
        data["companyTypes"].push(item);
    }
    if (Array.isArray(this.partnerTypes)) {
      data["partnerTypes"] = [];
      for (let item of this.partnerTypes)
        data["partnerTypes"].push(item);
    }
    if (Array.isArray(this.institutionTypes)) {
      data["institutionTypes"] = [];
      for (let item of this.institutionTypes)
        data["institutionTypes"].push(item);
    }
    if (Array.isArray(this.achievements)) {
      data["achievements"] = [];
      for (let item of this.achievements)
        data["achievements"].push(item.toJSON());
    }
    if (Array.isArray(this.studentTaskActions)) {
      data["studentTaskActions"] = [];
      for (let item of this.studentTaskActions)
        data["studentTaskActions"].push(item.toJSON());
    }
    if (Array.isArray(this.highSchoolProgramTaskActions)) {
      data["highSchoolProgramTaskActions"] = [];
      for (let item of this.highSchoolProgramTaskActions)
        data["highSchoolProgramTaskActions"].push(item.toJSON());
    }
    if (Array.isArray(this.placementTaskActions)) {
      data["placementTaskActions"] = [];
      for (let item of this.placementTaskActions)
        data["placementTaskActions"].push(item.toJSON());
    }
    if (Array.isArray(this.internshipExperienceFields)) {
      data["internshipExperienceFields"] = [];
      for (let item of this.internshipExperienceFields)
        data["internshipExperienceFields"].push(item.toJSON());
    }
    data["actionCategories"] = this.actionCategories ? this.actionCategories.toJSON() : <any>undefined;
    data["currentSeasonSettings"] = this.currentSeasonSettings ? this.currentSeasonSettings.toJSON() : <any>undefined;
    data["approachingSeasonSettings"] = this.approachingSeasonSettings ? this.approachingSeasonSettings.toJSON() : <any>undefined;
    data["scorecardDateSeasonSettings"] = this.scorecardDateSeasonSettings ? this.scorecardDateSeasonSettings.toJSON() : <any>undefined;
    data["semesterDateSeasonSettings"] = this.semesterDateSeasonSettings ? this.semesterDateSeasonSettings.toJSON() : <any>undefined;
    if (Array.isArray(this.learningContractConfig)) {
      data["learningContractConfig"] = [];
      for (let item of this.learningContractConfig)
        data["learningContractConfig"].push(item.toJSON());
    }
    if (Array.isArray(this.graduatingCapabilitiesConfig)) {
      data["graduatingCapabilitiesConfig"] = [];
      for (let item of this.graduatingCapabilitiesConfig)
        data["graduatingCapabilitiesConfig"].push(item.toJSON());
    }
    if (Array.isArray(this.preUniversityGraduatingCapabilitiesConfig)) {
      data["preUniversityGraduatingCapabilitiesConfig"] = [];
      for (let item of this.preUniversityGraduatingCapabilitiesConfig)
        data["preUniversityGraduatingCapabilitiesConfig"].push(item.toJSON());
    }
    if (Array.isArray(this.careerQuestionnaireConfig)) {
      data["careerQuestionnaireConfig"] = [];
      for (let item of this.careerQuestionnaireConfig)
        data["careerQuestionnaireConfig"].push(item.toJSON());
    }
    data["completionSourceConfig"] = this.completionSourceConfig ? this.completionSourceConfig.toJSON() : <any>undefined;
    if (Array.isArray(this.genderPronounsConfig)) {
      data["genderPronounsConfig"] = [];
      for (let item of this.genderPronounsConfig)
        data["genderPronounsConfig"].push(item.toJSON());
    }
    if (Array.isArray(this.atRiskReasonConfigs)) {
      data["atRiskReasonConfigs"] = [];
      for (let item of this.atRiskReasonConfigs)
        data["atRiskReasonConfigs"].push(item.toJSON());
    }
    if (Array.isArray(this.inactiveReasonConfigs)) {
      data["inactiveReasonConfigs"] = [];
      for (let item of this.inactiveReasonConfigs)
        data["inactiveReasonConfigs"].push(item.toJSON());
    }
    if (Array.isArray(this.studentTravelLocationsConfig)) {
      data["studentTravelLocationsConfig"] = [];
      for (let item of this.studentTravelLocationsConfig)
        data["studentTravelLocationsConfig"].push(item.toJSON());
    }
    if (Array.isArray(this.companyTaskActions)) {
      data["companyTaskActions"] = [];
      for (let item of this.companyTaskActions)
        data["companyTaskActions"].push(item.toJSON());
    }
    return data;
  }
}

export interface IPlatformSettingsModel {
  isMaintenance?: boolean | undefined;
  /** Tags that can be applied to students */
  studentTags?: string[] | undefined;
  /** Tags that can be applied to client companies */
  contactTags?: string[] | undefined;
  /** Tags that can be applied to client contacts */
  clientTags?: string[] | undefined;
  /** Tags that can be applied to partner companies */
  partnerTags?: string[] | undefined;
  /** Tags that can be applied to education companies */
  educationTags?: string[] | undefined;
  /** Tags that can be applied to volunteer companies */
  volunteerTags?: string[] | undefined;
  /** Tags that can be applied to work shadow students */
  workShadowTags?: string[] | undefined;
  /** Tags that can be applied to alumni students */
  alumniTags?: string[] | undefined;
  teams?: TeamDetailModel[] | undefined;
  subTeams?: SubTeamDetailModel[] | undefined;
  /** All Australian universities */
  universities?: UniversityModel[] | undefined;
  /** All industries and sub-industries */
  industries?: IndustryModel[] | undefined;
  /** Types of degrees */
  degreePools?: DegreePoolModel[] | undefined;
  /** Student status values */
  studentStatus?: StudentStatusEnum[] | undefined;
  /** Student status values */
  workShadowStatus?: WorkShadowStatusEnum[] | undefined;
  /** Student ICS status values */
  icsStatus?: IcsStatus[] | undefined;
  /** Client status values */
  clientStatus?: ClientStatus[] | undefined;
  /** Partner status values */
  partnerStatus?: PartnerStatus[] | undefined;
  /** Company pool status values */
  poolStatus?: PoolStatus[] | undefined;
  /** Status of students on pools */
  poolStudentStatus?: PoolStudentStatus[] | undefined;
  /** All email template substitution variables for settings */
  emailTemplateVariables?: VariableModel[] | undefined;
  /** All email templates */
  emailTemplates?: EmailTemplateModel[] | undefined;
  companyTypes?: string[] | undefined;
  partnerTypes?: string[] | undefined;
  institutionTypes?: string[] | undefined;
  achievements?: AchievementModel[] | undefined;
  studentTaskActions?: TaskModel[] | undefined;
  highSchoolProgramTaskActions?: TaskModel[] | undefined;
  placementTaskActions?: TaskModel[] | undefined;
  internshipExperienceFields?: InternshipExperienceFieldsModel[] | undefined;
  actionCategories?: ActionCategoriesModel | undefined;
  currentSeasonSettings?: SeasonPlatformSettingsModel | undefined;
  approachingSeasonSettings?: SeasonPlatformSettingsModel | undefined;
  scorecardDateSeasonSettings?: ScorecardDateSettingsModel | undefined;
  semesterDateSeasonSettings?: SeasonModel | undefined;
  learningContractConfig?: LearningContractConfigModel[] | undefined;
  graduatingCapabilitiesConfig?: GraduatingCapabilitiesModel[] | undefined;
  preUniversityGraduatingCapabilitiesConfig?: GraduatingCapabilitiesModel[] | undefined;
  careerQuestionnaireConfig?: CareerQuestionnaireConfigModel[] | undefined;
  completionSourceConfig?: CompletionSourceConfigModel | undefined;
  genderPronounsConfig?: GenderPronounsConfigModel[] | undefined;
  atRiskReasonConfigs?: AtRiskReasonConfigModel[] | undefined;
  inactiveReasonConfigs?: InactiveReasonConfigModel[] | undefined;
  studentTravelLocationsConfig?: AddressViewModel[] | undefined;
  companyTaskActions?: TaskModel[] | undefined;
}