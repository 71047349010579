export enum RetrieveSubmissionConfig {
  Recruitment = 'Recruitment',
  Engagement = 'Engagement',
  HSPEngagement = 'HSPEngagement',
  Internship = 'Internship',
  CompanyInternship = 'CompanyInternship',
}

export const recruitmentScorecardDefaultColumns = [
  'Advisor',
  'State',
  'PlacementHistory',
  'Program',
  'Period',
  'Status',
  'AppStatus',
  'CompleteOnboarding',
  'Company',
  'LastStep',
  'NextStep',
  'University',
  'DegreePool',
  'Degree',
  'Major',
  'InterviewAvailabiltiy',
  'Notes',
]

export const engagementScorecardDefaultColumns = [
  'Advisor',
  'State',
  'Status',
  'AppStatus',
  'Company',
  'OpportunityStatus',
  'Program',
  'University',
  'FirstSemester',
  'LastSemester',
  'GraduatingPlans',
  'GraduatingNotes',
  'CompleteGraduating',
  'GradLastStep',
  'GradNextStep',
  'AtRisk',
  'ICSStatus',
  'MonthlyMeeting',
  'Transcript',
  'ProofOfEnrolment',
  'InternshipDates',
  'Notes',
]

export const hspEngagementScorecardDefaultColumns = [
  'Advisor',
  'State',
  'Status',
  'AppStatus',
  'Company',
  'OpportunityStatus',
  'Notes',
]

export const internshipScorecardDefaultColumns = [
  'Advisor',
  'State',
  'PlacementHistory',
  'Program',
  'Status',
  'OpportunityStatus',
  'AppStatus',
  'Company',
  'PlacementTitle',
  'StartDate',
  'EndDate',
  'Manager',
  'ManagerPhone',
  'ManagerEmail',
  'Travel',
  'TravelInfo',
  'ManagerInductionCall',
  'ConfirmationLetterSent',
  'LearningContract',
  'EvaluationMeeting',
  // 'EvaluationForm',
  'InternEvaluation',
  'InternEval.Link',
  'InternshipExperienceForm',
  'Presentation',
  'WeeklyUpdates',
  'InternshipDates',
  'Notes',
]

export const companyInternshipScorecardDefaultColumns = [
  'Advisor',
  'State',
  'PlacementHistory',
  'Program',
  'Status',
  'University',
  'DegreePool',
  'Degree',
  'Major',
  'FirstSemester',
  'LastSemester',
  'GraduatingPlans',
  'GraduatingNotes',
  'ManagerInductionCall',
  'Travel',
  'TravelInfo',
  'PlacementStatus',
  'PlacementTitle',
  'PlacementStartDate',
  'PlacementEndDate',
  'Manager',
  'LearningContract',
  'WeeklyUpdates',
  'InternshipExperienceForm',
  'EvaluationMeeting',
  'InternEvaluation',
  'InternEval.Link',
  // 'EvaluationForm',
  'NextOp.Company',
  'NextOp.Title',
  'NextInt.Period',
  'Notes',
]
