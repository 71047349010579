import {all, call, takeLatest} from 'redux-saga/effects';
import {PayloadAction} from '@reduxjs/toolkit';
import {
  FORM_DRAFT_LIST,
  loadFormDraftsSuccess,
  loadFormDraftsError,
  saveFormDraftSuccess,
  saveFormDraftError,
  FORM_DRAFT,
  formDraftLoadSuccess,
  formDraftLoadError, resetFormDraftSelected
} from './Actions';
import authApi from '../../@template/services/auth/authApi/authenticatedApi';
import {apiConfig} from '../../config';
import {put} from '@redux-saga/core/effects';
import {FormDraftsEditModel} from "../../types/requests/FormDraftsEditModel";
import {FormDraftsModel} from "../../types/models/FormDraftsModel";
import {FormDraftIdentifier} from "../../types/models/FormDraftIdentifier";


export function* loadFormDraftsListSaga() {
  console.log('loadFormDraftsListSaga')
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'GET',
      url: `${apiConfig.coreFormsPath}`,
    });
    const result = res.data.map((x: any) => FormDraftIdentifier.fromJS(x));
    yield put(loadFormDraftsSuccess(result));
  } catch (error) {
    console.log(error)
    yield put(loadFormDraftsError(error));
  }
}

export function* completeFormDraftSaga(data: FormDraftIdentifier) {
  console.log(`completeFormDraftSaga - ${data.contextType} - ${data.additionalIdentifier}`)
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'POST',
      url: `${apiConfig.coreFormsPath}/complete`,
      data: data
    });
    yield put(saveFormDraftSuccess());
  } catch (error) {
    yield put(saveFormDraftError(error));
  }
}

export function* saveFormDraftSaga(data: FormDraftsEditModel) {
  console.log(`saveFormDraftSaga - ${data.contextType} - ${data.additionalIdentifier}`)
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'POST',
      url: `${apiConfig.coreFormsPath}`,
      data: data
    });

    yield put(saveFormDraftSuccess());
  } catch (error) {
    yield put(saveFormDraftError(error));
  }
}

export function* loadFormDraftSaga(data: FormDraftIdentifier) {
  console.log(`loadFormDraftSaga - ${data.contextType} - ${data.additionalIdentifier}`)
  try {
    const res = yield authApi.request({
      baseURL: apiConfig.coreUrl,
      method: 'POST',
      url: `${apiConfig.coreFormsPath}/single`,
      data: data
    });
    const result = FormDraftsModel.fromJS(res.data);
    if (!(result.isCompleted || result.isIgnored)) {
      yield put(formDraftLoadSuccess(result));
    } else {
      yield put(formDraftLoadSuccess(undefined));
    }
  } catch (error) {
    yield put(formDraftLoadError(error));
  }
}


export function* watchLoadFormDraftsListSaga() {
  yield takeLatest(FORM_DRAFT_LIST.LOAD, () => loadFormDraftsListSaga());
}
export function* watchCompleteFormDraftSaga() {
  yield takeLatest(FORM_DRAFT.COMPLETE, ({payload}: PayloadAction<FormDraftIdentifier>) => completeFormDraftSaga(payload));
}
export function* watchSaveFormDraftSaga() {
  yield takeLatest(FORM_DRAFT.SAVE, ({payload}: PayloadAction<FormDraftsEditModel>) => saveFormDraftSaga(payload));
}
export function* watchLoadFormDraftSaga() {
  yield takeLatest(FORM_DRAFT.LOAD, ({payload}: PayloadAction<FormDraftIdentifier>) => loadFormDraftSaga(payload));
}

export default function* formDraftSaga() {
  yield all ([
    call(watchLoadFormDraftsListSaga),
    call(watchCompleteFormDraftSaga),
    call(watchSaveFormDraftSaga),
    call(watchLoadFormDraftSaga),
  ])
}
