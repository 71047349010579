import {AddressEditModel} from './AddressEditModel';

export const defaultForContactEditDetailModel = () => {
  return ContactEditDetailModel.fromJS({
    companyId: undefined,
    contactTags: [],
    locationAddress: {
      line1: '',
      line2: '',
      suburb: '',
      postcode: '',
      countryCode: 'AU',
      state: 'NSW'
    },
  })
}

export class ContactEditDetailModel implements IContactEditDetailModel {
  companyId?: string | undefined;
  contactTags?: string[] | undefined;
  locationAddress?: AddressEditModel | undefined;
  jobTitle?: string | undefined;
  isPrimary?: boolean | undefined;
  isSecondary?: boolean | undefined;
  isBilling?: boolean | undefined;
  isStudentContact?: boolean | undefined;
  isExecutiveSponsor?: boolean | undefined;

  constructor(data?: IContactEditDetailModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.companyId = Data["companyId"];
      if (Array.isArray(Data["contactTags"])) {
        this.contactTags = [] as any;
        for (let item of Data["contactTags"])
          this.contactTags!.push(item);
      }
      this.locationAddress = Data["locationAddress"] ? AddressEditModel.fromJS(Data["locationAddress"]) : <any>undefined;
      this.jobTitle = Data["jobTitle"];
      this.isPrimary = Data["isPrimary"];
      this.isSecondary = Data["isSecondary"];
      this.isBilling = Data["isBilling"];
      this.isStudentContact = Data["isStudentContact"];
      this.isExecutiveSponsor = Data["isExecutiveSponsor"];
    }
  }

  static fromJS(data: any): ContactEditDetailModel {
    data = typeof data === 'object' ? data : {};
    let result = new ContactEditDetailModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["companyId"] = this.companyId;
    if (Array.isArray(this.contactTags)) {
      data["contactTags"] = [];
      for (let item of this.contactTags)
        data["contactTags"].push(item);
    }
    data["locationAddress"] = this.locationAddress ? this.locationAddress.toJSON() : <any>undefined;
    data["jobTitle"] = this.jobTitle;
    data["isPrimary"] = this.isPrimary;
    data["isSecondary"] = this.isSecondary;
    data["isBilling"] = this.isBilling;
    data["isStudentContact"] = this.isStudentContact;
    data["isExecutiveSponsor"] = this.isExecutiveSponsor;
    return data;
  }
}

export interface IContactEditDetailModel {
  companyId?: string | undefined;
  contactTags?: string[] | undefined;
  locationAddress?: AddressEditModel | undefined;
  jobTitle?: string | undefined;
  isPrimary?: boolean | undefined;
  isSecondary?: boolean | undefined;
  isBilling?: boolean | undefined;
  isStudentContact?: boolean | undefined;
  isExecutiveSponsor?: boolean | undefined;
}