import {createReducer, PayloadAction} from '@reduxjs/toolkit';
import {CountryModel} from '../../types/models/CountryModel';
import {VariableModel} from '../../types/models/VariableModel';
import {EmailTemplateModel} from '../../types/models/EmailTemplateModel';
import {AchievementModel} from '../../types/models/AchievementModel';
import {TaskModel} from '../../types/models/TaskModel';
import {SeasonPlatformSettingsModel} from '../../types/models/SeasonPlatformSettingsModel';
import {ScorecardDateSettingsModel} from '../../types/models/ScoreCardDateSettingsModel';
import {SeasonModel} from '../../types/models/SeasonModel';
import {LearningContractConfigModel} from '../../types/models/LearningContractConfigModel';
import {GraduatingCapabilitiesModel} from '../../types/models/GraduatingCapabilitiesModel';
import {CompletionSourceConfigModel} from '../../types/models/CompletionSourceConfigModel';
import {GenderPronounsConfigModel} from '../../types/models/GenderPronounsConfigModel';
import {AtRiskReasonConfigModel} from '../../types/models/AtRiskReasonConfigModel';
import {EMAIL_TEMPLATES, LOCALITIES, SESSION, TAGS} from './Actions';
import {TeamDetailModel} from '../../types/models/TeamDetailModel';
import {SubTeamDetailModel} from '../../types/models/SubTeamDetailModel';
import {UniversityModel} from '../../types/models/UniversityModel';
import {IndustryModel} from '../../types/models/IndustryModel';
import {DegreePoolModel} from '../../types/models/DegreePoolModel';
import {ActionCategoriesModel} from '../../types/models/ActionCategoriesModel';
import {PlatformSettingsModel} from '../../types/models/PlatformSettingsModel';
import {FileMetaModel, IFileMetaModel} from '../../types/models/FileMetaModel';
import {TagListModel} from '../../types/models/TagListModel';
import {CareerQuestionnaireConfigModel} from '../../types/models/CareerQuestionnaireConfigModel';
import {InactiveReasonConfigModel} from '../../types/models/InactiveReasonConfigModel';
import {AddressViewModel} from '../../types/models/AddressViewModel';
import {InternshipExperienceFieldsModel} from '../../types/models/InternshipExperienceFieldsModel';

export interface ISessionState {
  countriesLoading: boolean;
  countriesError: string | undefined;
  sessionLoading: boolean;
  sessionError: string | undefined;
  sessionInit: boolean;
  countriesInit: boolean;
  isMaintenance: boolean;
  countries: CountryModel[];
  studentTags?: string[] | undefined;
  contactTags?: string[] | undefined;
  clientTags?: string[] | undefined;
  partnerTags?: string[] | undefined;
  volunteerTags?: string[] | undefined;
  workShadowTags?: string[] | undefined;
  alumniTags?: string[] | undefined;
  educationTags?: string[] | undefined;
  fileTags: string[] | undefined;
  fileSystemTags: string[] | undefined;
  teams?: TeamDetailModel[];
  subTeams?: SubTeamDetailModel[];
  universities?: UniversityModel[];
  industries?: IndustryModel[];
  degreePools?: DegreePoolModel[];
  emailTemplateVariables?: VariableModel[];
  emailTemplates?: EmailTemplateModel[];
  emailTemplateAttachments: FileMetaModel[];
  companyTypes?: string[];
  partnerTypes?: string[];
  institutionTypes?: string[];
  achievements?: AchievementModel[];
  studentTaskActions?: TaskModel[];
  highSchoolProgramTaskActions?: TaskModel[];
  placementTaskActions?: TaskModel[];
  internshipExperienceFields?: InternshipExperienceFieldsModel[];
  actionCategories?: ActionCategoriesModel | undefined;
  currentSeasonSettings?: SeasonPlatformSettingsModel | undefined;
  approachingSeasonSettings?: SeasonPlatformSettingsModel | undefined;
  scorecardDateSeasonSettings?: ScorecardDateSettingsModel | undefined;
  semesterDateSeasonSettings?: SeasonModel | undefined;
  learningContractConfig?: LearningContractConfigModel[];
  graduatingCapabilitiesConfig?: GraduatingCapabilitiesModel[];
  preUniversityGraduatingCapabilitiesConfig?: GraduatingCapabilitiesModel[];
  completionSourceConfig?: CompletionSourceConfigModel | undefined;
  careerQuestionnaireConfig?: CareerQuestionnaireConfigModel[];
  genderPronounsConfig?: GenderPronounsConfigModel[];
  atRiskReasonConfigs?: AtRiskReasonConfigModel[];
  inactiveReasonConfigs?: InactiveReasonConfigModel[];
  templateFileList?: IFileMetaModel[];
  selectedTemplateId?: string | undefined;
  studentTravelLocationConfig?: AddressViewModel[];
  companyTaskActions?: TaskModel[];
}

const initialState: ISessionState = {
  countriesLoading: false,
  countriesError: undefined,
  sessionLoading: false,
  sessionError: undefined,
  sessionInit: false,
  countriesInit: false,
  isMaintenance: false,
  countries: [],
  studentTags: [],
  clientTags: [],
  partnerTags:  [],
  contactTags: [],
  volunteerTags: [],
  workShadowTags: [],
  alumniTags: [],
  educationTags: [],
  fileTags: [],
  fileSystemTags: [],
  companyTypes: [],
  partnerTypes: [],
  institutionTypes: [],
  degreePools: [],
  universities: [],
  teams: [],
  subTeams: [],
  emailTemplateVariables: [],
  emailTemplates: [],
  emailTemplateAttachments: [],
  achievements: [],
  studentTaskActions: [],
  highSchoolProgramTaskActions: [],
  placementTaskActions: [],
  internshipExperienceFields: [],
  currentSeasonSettings: undefined,
  approachingSeasonSettings: undefined,
  scorecardDateSeasonSettings: undefined,
  semesterDateSeasonSettings: undefined,
  learningContractConfig: undefined,
  graduatingCapabilitiesConfig: [],
  preUniversityGraduatingCapabilitiesConfig: [],
  completionSourceConfig: undefined,
  careerQuestionnaireConfig: [],
  genderPronounsConfig: [],
  atRiskReasonConfigs:[],
  inactiveReasonConfigs: [],
  templateFileList: [],
  selectedTemplateId: undefined,
  studentTravelLocationConfig: undefined,
  companyTaskActions: [],
}


const sessionReducer = createReducer(initialState, {
  [SESSION.LOAD]: (state) => {
    state.sessionLoading = true;
    state.sessionInit = false;
    state.sessionError = undefined;
  },
  [SESSION.LOADED]: (state, action: PayloadAction<PlatformSettingsModel>) => {
    state.sessionLoading = false;
    state.sessionError = undefined;
    state.sessionInit = true;
    state.studentTags = action.payload.studentTags ?? [];
    state.clientTags = action.payload.clientTags ?? [];
    state.partnerTags = action.payload.partnerTags ?? [];
    state.contactTags = action.payload.contactTags ?? [];
    state.volunteerTags = action.payload.volunteerTags ?? [];
    state.workShadowTags = action.payload.workShadowTags ?? [];
    state.alumniTags = action.payload.alumniTags ?? [];
    state.educationTags = action.payload.educationTags ?? [];
    state.companyTypes = action.payload.companyTypes ?? [];
    state.partnerTypes = action.payload.partnerTypes ?? [];
    state.institutionTypes = action.payload.institutionTypes ?? [];
    state.degreePools = action.payload.degreePools ?? [];
    state.universities = action.payload.universities ?? [];
    state.teams = action.payload.teams ?? [];
    state.subTeams = action.payload.subTeams ?? [];
    state.emailTemplateVariables = action.payload.emailTemplateVariables ?? [];
    state.emailTemplates = action.payload.emailTemplates ?? [];
    state.achievements = action.payload.achievements ?? [];
    state.studentTaskActions = action.payload.studentTaskActions ?? [];
    state.highSchoolProgramTaskActions = action.payload.highSchoolProgramTaskActions ?? [];
    state.placementTaskActions = action.payload.placementTaskActions ?? [];
    state.internshipExperienceFields = action.payload.internshipExperienceFields ?? [];
    state.currentSeasonSettings = action.payload.currentSeasonSettings;
    state.approachingSeasonSettings = action.payload.approachingSeasonSettings;
    state.scorecardDateSeasonSettings = action.payload.scorecardDateSeasonSettings;
    state.semesterDateSeasonSettings = action.payload.semesterDateSeasonSettings;
    state.learningContractConfig = action.payload.learningContractConfig ?? [];
    state.graduatingCapabilitiesConfig = action.payload.graduatingCapabilitiesConfig ?? [];
    state.preUniversityGraduatingCapabilitiesConfig = action.payload.preUniversityGraduatingCapabilitiesConfig ?? [];
    state.completionSourceConfig = action.payload.completionSourceConfig;
    state.careerQuestionnaireConfig = action.payload.careerQuestionnaireConfig?? [];
    state.genderPronounsConfig = action.payload.genderPronounsConfig ?? [];
    state.atRiskReasonConfigs = action.payload.atRiskReasonConfigs ?? [];
    state.inactiveReasonConfigs = action.payload.inactiveReasonConfigs ?? [];
    state.actionCategories = action.payload.actionCategories;
    state.studentTravelLocationConfig = action.payload.studentTravelLocationsConfig?? [];
    state.companyTaskActions = action.payload.companyTaskActions ?? [];
  },
  [TAGS.UPDATE_FILE]: (state, action: PayloadAction<string[]>) => {
    state.fileTags = action.payload;
  },
  [TAGS.UPDATE_SYSTEM_FILE]: (state, action: PayloadAction<string[]>) => {
    state.fileSystemTags = action.payload;
  },
  [TAGS.UPDATE_STUDENT]: (state, action: PayloadAction<string[]>) => {
    state.studentTags = action.payload;
  },
  [TAGS.UPDATE_CLIENT]: (state, action: PayloadAction<string[]>) => {
    state.clientTags = action.payload;
  },
  [TAGS.UPDATE_PARTNER]: (state, action: PayloadAction<string[]>) => {
    state.partnerTags = action.payload;
  },
  [TAGS.UPDATE_CONTACT]: (state, action: PayloadAction<string[]>) => {
    state.contactTags = action.payload;
  },
  [TAGS.UPDATE_VOLUNTEER]: (state, action: PayloadAction<string[]>) => {
    state.volunteerTags = action.payload;
  },
  [TAGS.UPDATE_WORK_SHADOW]: (state, action: PayloadAction<string[]>) => {
    state.workShadowTags = action.payload;
  },
  [TAGS.UPDATE_ALUMNI]: (state, action: PayloadAction<string[]>) => {
    state.alumniTags = action.payload;
  },
  [TAGS.UPDATE_EDUCATION]: (state, action: PayloadAction<string[]>) => {
    state.educationTags = action.payload;
  },
  [LOCALITIES.LOAD]: (state) => {
    state.countriesLoading = true;
    state.countriesError = undefined;
  },
  [LOCALITIES.ERROR]: (state, action: PayloadAction<string>) => {
    state.countriesLoading = false;
    state.countriesError = action.payload;
  },
  [LOCALITIES.LOADED]: (state, action: PayloadAction<CountryModel[]>) => {
    state.countriesLoading = false;
    state.countriesError = undefined;
    state.countriesInit = true;
    state.countries = action.payload;
  },
  [EMAIL_TEMPLATES.FILES_LISTED]: (state, action: PayloadAction<IFileMetaModel[]>) => {
    state.templateFileList = action.payload;
  },
  [EMAIL_TEMPLATES.SELECT_TEMPLATE]: (state, action: PayloadAction<string | undefined>) => {
    state.selectedTemplateId = action.payload
  }
});

export default sessionReducer;
