export enum StudentStatusEnum {
  Potential = "Potential",
  Pipeline = "Pipeline",
  Intern = "Intern",
  AlumniPending = "AlumniPending",
  Alumni = "Alumni",
  Inactive = "Inactive",
}

export enum StudentSearchStatusEnum {
  Potential = "Potential",
  Pipeline = "Pipeline",
  Intern = "Intern",
  AlumniPending = "AlumniPending",
  Inactive = "Inactive",
}

export enum WorkShadowStatusEnum {
  Potential = "Potential",
  Participant = "Participant",
  Pipeline = "Pipeline",
  Intern = "Intern",
  // Confirmed = "Confirmed",
  // WSAlumni = "WSAlumni",
  Inactive = "Inactive",
  Completed = "Completed"
}

export enum AlumniStatusEnum {
  Early = "Early professional",
  Mid = "Mid professional",
  Experienced = "Experienced professional",
  Inactive = "Inactive",
}

export enum IcsStatus {
  NotSet = "NotSet",
  ApplicationPending = "ApplicationPending",
  Active = "Active",
  Hold = "Hold",
  Deferred = "Deferred",
  Graduated = "Graduated",
  Declined = "Declined",
  Ceased = "Ceased",
  No = "No",
}

export enum ClientStatus {
  Potential = "Potential",
  Pipeline = "Pipeline",
  Active = "Active",
  Inactive = "Inactive",
}

export enum PartnerStatus {
  Potential = "Potential",
  Active = "Active",
  Inactive = "Inactive",
}

export enum EducationStatus {
  Potential = "Potential",
  Active = "Active",
  Inactive = "Inactive",
}
// NOTE: Education status options have been renamed. use the options below when NOT submitting to backend or comparing values.
// Use purely for display purposes
export const educationStatusOptions = [
  {
    value: EducationStatus.Potential,
    name: "Non-MOU (Non-Priority)",
  },
  {
    value: EducationStatus.Active,
    name: "MOU Partner",
  },
  {
    value: EducationStatus.Inactive,
    name: "Inactive",
  },
]

export enum PoolStatus {
  Potential = "Potential",
  Closed = "Closed",
}

export enum HSPPoolStatus {
  Potential = "Potential",
  Closed = "Closed",
}

export enum PoolType {
  Shortlist = 'Shortlist',
  Returners = 'Returners',
  Team = 'Team',
  Specific = 'Specific',
}

export enum HSPPoolType {
  Shortlist = 'Shortlist',
  Returners = 'Returners',
  Team = 'Team',
  Specific = 'Specific',
}

export enum PoolStudentStatus {
  Shortlisted = "Shortlisted",
  SentToEmployer = "SentToEmployer",
  InterviewRequested = "InterviewRequested",
  InterviewBooked = "InterviewBooked",
  Interviewed = "Interviewed",
  FeedbackReceived = "FeedbackReceived",
  Offer = "Offer",
  Declined = "Declined",
  Placed = "Placed",
  IntentToReturn = "IntentToReturn",
}

export enum OpportunityStudentStatus {
  Shortlisted = "Shortlisted",
  ProfileReady = "ProfileReady",
  SentToEmployer = "SentToEmployer",
  InterviewRequested = "InterviewRequested",
  InterviewBooked = "InterviewBooked",
  Interviewed = "Interviewed",
  FeedbackReceived = "FeedbackReceived",
  Offer = "Offer",
  Placed = "Placed",
  IntentToReturn = "IntentToReturn",
  Declined = "Declined",
}

export enum PhoneModelPhoneType {
  Mobile = "Mobile",
  Home = "Home",
  Work = "Work",
  Other = "Other",
}

export enum Gender {
  Male = "Male",
  Female = "Female",
  Other = "Other",
}

export enum StudentTypes {
  WorkShadow = 'Work Shadow',
  HighSchoolProgram = 'High School Program',
  University = 'University',
  Alumni = 'Alumni'
}


export enum IcsSettingsEditIcsStatus {
  NotSet = "NotSet",
  ApplicationPending = "ApplicationPending",
  Active = "Active",
  Hold = "Hold",
  Deferred = "Deferred",
  Graduated = "Graduated",
  Declined = "Declined",
  Ceased = "Ceased",
  No = "No",
}

export enum OpportunityStudentViewOpportunityStudentStatus {
  Shortlisted = "Shortlisted",
  ProfileReady = "ProfileReady",
  SentToEmployer = "SentToEmployer",
  InterviewRequested = "InterviewRequested",
  InterviewBooked = "InterviewBooked",
  Interviewed = "Interviewed",
  FeedbackReceived = "FeedbackReceived",
  Offer = "Offer",
  Placed = "Placed",
  IntentToReturn = "IntentToReturn",
  Declined = "Declined",
}

export enum HSPOpportunityStudentViewOpportunityStudentStatus {
  Shortlisted = "Shortlisted",
  ProfileReady = "ProfileReady",
  SentToEmployer = "SentToEmployer",
  InterviewRequested = "InterviewRequested",
  InterviewBooked = "InterviewBooked",
  Interviewed = "Interviewed",
  FeedbackReceived = "FeedbackReceived",
  Offer = "Offer",
  Placed = "Placed",
  IntentToReturn = "IntentToReturn",
  Declined = "Declined",
}

export enum PoolStudentViewPoolStudentStatus {
  Shortlisted = "Shortlisted",
  SentToEmployer = "SentToEmployer",
  InterviewRequested = "InterviewRequested",
  InterviewBooked = "InterviewBooked",
  Interviewed = "Interviewed",
  FeedbackReceived = "FeedbackReceived",
  Offer = "Offer",
  Placed = "Placed",
  IntentToReturn = "IntentToReturn",
  Declined = "Declined",
}

export enum HSPPoolStudentViewPoolStudentStatus {
  Shortlisted = "Shortlisted",
  SentToEmployer = "SentToEmployer",
  InterviewRequested = "InterviewRequested",
  InterviewBooked = "InterviewBooked",
  Interviewed = "Interviewed",
  FeedbackReceived = "FeedbackReceived",
  Offer = "Offer",
  Placed = "Placed",
  IntentToReturn = "IntentToReturn",
  Declined = "Declined",
}


export enum StudentPoolStepModelSetToStatus {
  Shortlisted = "Shortlisted",
  SentToEmployer = "SentToEmployer",
  InterviewRequested = "InterviewRequested",
  InterviewBooked = "InterviewBooked",
  Interviewed = "Interviewed",
  FeedbackReceived = "FeedbackReceived",
  Offer = "Offer",
  Declined = "Declined",
  Placed = "Placed",
  IntentToReturn = "IntentToReturn",
}

export enum HSPPoolStepModelSetToStatus {
  Shortlisted = "Shortlisted",
  SentToEmployer = "SentToEmployer",
  InterviewRequested = "InterviewRequested",
  InterviewBooked = "InterviewBooked",
  Interviewed = "Interviewed",
  FeedbackReceived = "FeedbackReceived",
  Offer = "Offer",
  Declined = "Declined",
  Placed = "Placed",
  IntentToReturn = "IntentToReturn",
}


export enum IcsStudentStatusEnum {
  Potential = "Potential",
  Pipeline = "Pipeline",
  Intern = "Intern",
  AlumniPending = "AlumniPending",
  Alumni = "Alumni",
  Inactive = "Inactive",
}

export enum ClientOnboardingStepModelSetToStatus {
  Potential = "Potential",
  Pipeline = "Pipeline",
  Active = "Active",
  Inactive = "Inactive",
}

export enum EducationOnboardingStepModelSetToStatus {
  Potential = "Potential",
  Pipeline = "Pipeline",
  Active = "Active",
  Inactive = "Inactive",
}

export enum NotificationModelNotificationType {
  Student = "Student",
  Client = "Client",
  Tasks = "Tasks",
  Partner = "Partner",
  Global = "Global",
}

export enum ClientDetailModelClientStatus {
  Potential = "Potential",
  Pipeline = "Pipeline",
  Active = "Active",
  Inactive = "Inactive",
}

export enum PartnerDetailModelPartnerStatus {
  Potential = "Potential",
  Pipeline = "Pipeline",
  Active = "Active",
  Inactive = "Inactive",
}

export enum EducationDetailModelEducationStatus {
  Potential = "Potential",
  Pipeline = "Pipeline",
  ProspectiveMOUPartner = "Prospective MOU Partner",
  Active = "Active",
  Inactive = "Inactive",
}

export const educationDetailStatusOptions = [
  {
    value: EducationDetailModelEducationStatus.Potential,
    name: "Non-MOU (Non-Priority)",
  },
  {
    value: EducationDetailModelEducationStatus.Pipeline,
    name: "Non-MOU (Priority)",
  },
  {
    value: EducationDetailModelEducationStatus.ProspectiveMOUPartner,
    name: "Prospective MOU Partner",
  },
  {
    value: EducationDetailModelEducationStatus.Active,
    name: "MOU Partner",
  },
  {
    value: EducationDetailModelEducationStatus.Inactive,
    name: "Inactive",
  },
]

export enum PlacementCompleteEditModelCompleteOption {
  IntentToReturn = "IntentToReturn",
  ReOpen = "ReOpen",
  ReturnToPipeline = "ReturnToPipeline",
  Leaving = "Leaving",
  Graduating = "Graduating",
  SameTeam = "SameTeam",
}

export enum PlacementCancelModelCancelOption {
  TransferSeason = "TransferSeason",
  ReturnToSeason = "ReturnToSeason",
  ReturnToPipeline = "ReturnToPipeline",
  Graduating = "Graduating",
}

export enum HSPPlacementCancelModelCancelOption {
  TransferSeason = "TransferSeason",
  ReturnToSeason = "ReturnToSeason",
  ReturnToPipeline = "ReturnToPipeline",
  Graduating = "Graduating",
}

export enum StudentExternalDetailModelStudentStatus {
  Potential = "Potential",
  Pipeline = "Pipeline",
  Intern = "Intern",
  AlumniPending = "AlumniPending",
  Alumni = "Alumni",
  Inactive = "Inactive",
}

export enum FileMetaModelContentType {
  Unknown = "unknown",
  Messaging = "messaging",
  Company = "company",
  Pool = "pool",
  Library = "library",
  System = "system",
  User = "user",
  Person = "person",
  Images = "images",
}

export enum FileUpdateMetaModelContentType {
  Unknown = "unknown",
  Messaging = "messaging",
  Company = "company",
  Pool = "pool",
  Library = "library",
  System = "system",
  User = "user",
  Person = "person",
  Images = "images",
}

export enum PeopleStatus {
  NoType = "NoType",
  Student = "Student",
  CompanyContact = "CompanyContact",
  Volunteer = "Volunteer",
  WorkShadow = "WorkShadow",
  BuddyForWorkShadow = "BuddyForWorkShadow",
  Advisor = "Advisor",
}

export enum MessagingInternalContext {
  STUDENT = 'InternalStudent',
  CONTACT = 'InternalContact',
  COMPANY = 'InternalCompany',
  OPPORTUNITY = 'InternalOpportunity',
  HSPOPPORTUNITY = 'InternalHSPOpportunity',
  POOL = 'InternalPool',
  HSPPOOL = 'InternalHSPPool',
  INTERNSHIP = 'InternalInternship',
  HSPINTERNSHIP = 'InternalHSPInternship',
  VOLUNTEER = 'InternalVolunteer',
  WORK_SHADOW = 'InternalWorkShadow',
  ALUMNI = 'InternalAlumni',
  MAILING = 'InternalMailing',
}

export enum MessagingExternalContext {
  STUDENT = 'ExternalStudent',
  CONTACT = 'ExternalContact',
  COMPANY = 'ExternalCompany',
  POOL = 'ExternalPool',
  INTERNSHIP = 'ExternalInternship',
}

export enum StudentTaskTypes {
  Onboarding = 'Onboarding',
  Graduating = 'Graduating',
  Pool = 'Pool',
  Placement = 'Placement',
}

export enum CompanyStream {
  Employer = 'Employer',
  Partner = 'Partner',
  Education = 'Education'
}

export enum CompanyTypes {
  Billable = 'Billable',
  TenByTen = '10x10',
  NonBillable = 'Non-billable'
}

export enum DraftContextTypes {
  ActivityFeed = 'ActivityFeed',
  Note = 'Note',
  EmployerSend = 'EmployerSend',
}

// draft context is prefixed category
export enum FormDraftContextTypes {
  AlumniStepComplete = 'AlumniStepComplete',
  StudentAddOpportunity = 'StudentAddOpportunity',
  HspAddOpportunity = 'HspAddOpportunity',
  CompanyOnboardingStepComplete = 'CompanyOnboardingStepComplete', // TODO: TEMPLATE
  SettingsEmailTemplateEdit = 'SettingsEmailTemplateEdit', //TODO: PAGE EDITS
  MailingListEdit = 'MailingListEdit', // TODO PAGE
  MailingListSendMessage = 'MailingListSendMessage', // TODO: TEMPLATE ID
  CreateSchedule = 'CreateSchedule', // TODO: unfinished??
  TaskCancelInternalTask = 'TaskCancelInternalTask',
  TaskCancelSharedTask = 'TaskCancelSharedTask',
  TaskCompleteTask = 'TaskCompleteTask', // TEST
  TaskCompleteInternEvaluationFiles = 'TaskCompleteInternEvaluationFiles',
  TaskCompletePreScreenTask = 'TaskCompletePreScreenTask',
  TaskWeeklyUpdate = 'TaskWeeklyUpdate',
  TaskCancelCompanyExternalContact = 'TaskCancelCompanyExternalContact',
  OpportunitySendStudents = 'OpportunitySendStudents',
  OpportunitySendStudentsEmail = 'OpportunitySendStudentsEmail',
  HspDropOpportunity = 'HspDropOpportunity',
  StudentDropOpportunity = 'StudentDropOpportunity',
  HspDropPool = 'HspDropPool',
  StudentDropPool = 'StudentDropPool',
  StudentRestoreOpportunity = 'StudentRestoreOpportunity',
  AlumniCancelPlacement = 'AlumniCancelPlacement',
  StudentCancelReturnToPipeline = 'StudentCancelReturnToPipeline',
  StudentCancelTransferSeason = 'StudentCancelTransferSeason',
  StudentCompletePlacementChange = 'StudentCompletePlacementChange',
  StudentCompletePlacementLeave = 'StudentCompletePlacementLeave',
  HspCancelAlumni = 'HspCancelAlumni',
  HspCancelReturnToPipeline = 'HspCancelReturnToPipeline',
  HspCancelTransferSeason = 'HspCancelTransferSeason',
  HspCompleteChange = 'HspCompleteChange',
  HspCompleteLeave = 'HspCompleteLeave',
  HspPlacementStep = 'HspPlacementStep',
  StudentInternEvaluationCreate = 'StudentInternEvaluationCreate',
  StudentPlacementStepComplete = 'StudentPlacementStepComplete',
  StudentPlacementReOpen = 'StudentPlacementReOpen',
  HspPlacementDetail = 'HspPlacementDetail',
  StudentPlacementDetail = 'StudentPlacementDetail',
  StudentPoolStepComplete = 'StudentPoolStepComplete',
  StudentRestorePool = 'StudentRestorePool',
  StudentCopyPool = 'StudentCopyPool',
  StudentEditOpportunity = 'StudentEditOpportunity',
  HspCopyPool = 'HspCopyPool',
  HspEditOpportunity = 'HspEditOpportunity',
  StudentAddAchievement = 'StudentAddAchievement',
  StudentInviteToApp = 'StudentInviteToApp',
  StudentTaskAssign = 'StudentTaskAssign',
  StudentStepComplete = 'StudentStepComplete', //TODO: TEMPLATE
  StudentMonthlyMeeting = 'StudentMonthlyMeeting',
  StudentEmployerProfileEdit = 'StudentEmployerProfileEdit',
  HspAssignTask = 'HspAssignTask',
  HspStepComplete = 'HspStepComplete',
}

export enum PlacementTypes {
  inPerson = 'In Person',
  flexible = 'Flexible',
  remote = 'Remote'
}

export enum MailingListActionEnum {
  Student = 'StudentMailingList',
  Contact = 'ContactMailingList',
  Volunteer = 'VolunteerMailingList',
  WorkShadow = 'WorkShadowMailingList',
  Alumni = 'AlumniMailingList',
  Engagement = 'EngagementMailingList',
  Placement = 'PlacementMailingList',
  Scorecard = 'ScorecardMailingList',
  Company = 'CompanyMailingList',
}

export enum AlumniArchiveReasonEnum {
  unsubscribe = 'Unsubscribed',
  discretion = 'CareerTrackers Discretion',
  unconfirmed = 'Unconfirmed'
}

export enum UniversityArchiveReasonEnum {
  nonUniversity = 'Not at university',
  challenges = 'Academic challenges',
  partTime = 'Part-time study',
  exchange = 'Exchange',
}

export enum StudentOnboardingStepModelSetToStatus {
  Potential = "Potential",
  Pipeline = "Pipeline",
  Intern = "Intern",
  AlumniPending = "AlumniPending",
  Alumni = "Alumni",
  Inactive = "Inactive",
}