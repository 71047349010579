export class CompanyContactModel implements ICompanyContactModel {
  personId?: string | undefined;
  jobTitle?: string | undefined;
  quickNotes?: string | undefined;
  isPrimary?: boolean | undefined;
  isSecondary?: boolean | undefined;
  isExecutiveSponsor?: boolean | undefined;
  isDeleted?: boolean | undefined;

  constructor(data?: ICompanyContactModel) {
    if (data) {
      for (var property in data) {
        if (data.hasOwnProperty(property))
          (<any>this)[property] = (<any>data)[property];
      }
    }
  }

  init(Data?: any) {
    if (Data) {
      this.personId = Data["personId"];
      this.jobTitle = Data["jobTitle"];
      this.quickNotes = Data["quickNotes"];
      this.isPrimary = Data["isPrimary"];
      this.isSecondary = Data["isSecondary"];
      this.isDeleted = Data["isDeleted"];
      this.isExecutiveSponsor = Data["isExecutiveSponsor"];
    }
  }

  static fromJS(data: any): CompanyContactModel {
    data = typeof data === 'object' ? data : {};
    let result = new CompanyContactModel();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data["personId"] = this.personId;
    data["jobTitle"] = this.jobTitle;
    data["quickNotes"] = this.quickNotes;
    data["isPrimary"] = this.isPrimary;
    data["isSecondary"] = this.isSecondary;
    data["isDeleted"] = this.isDeleted;
    data["isExecutiveSponsor"] = this.isExecutiveSponsor;
    return data;
  }
}

export interface ICompanyContactModel {
  personId?: string | undefined;
  jobTitle?: string | undefined;
  quickNotes?: string | undefined;
  isPrimary?: boolean | undefined;
  isSecondary?: boolean | undefined;
  isDeleted?: boolean | undefined;
  isExecutiveSponsor?: boolean | undefined;
}