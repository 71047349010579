import { createSelector } from '@reduxjs/toolkit';
import { ISessionState} from './Reducer';
import {ActionTypeReferenceModel} from '../../types/models/ActionTypeReferenceModel';

/*
* Note that selectors for value props no longer needed and with immer state,
* we can just expose the whole tree
* */
export const selectSession = (state: { session: ISessionState }) => state.session;

export const selectSessionInit = createSelector(
  selectSession,
  (state) => state.sessionInit
);
export const selectSessionLoading = createSelector(
  selectSession,
  (state) => state.sessionLoading
);
export const selectSessionError = createSelector(
  selectSession,
  (state) => state.sessionError
);

export const selectCountriesInit = createSelector(
  selectSession,
  (state) => state.countriesInit
);
export const selectCountriesLoading = createSelector(
  selectSession,
  (state) => state.countriesLoading
);
export const selectCountriesError = createSelector(
  selectSession,
  (state) => state.countriesError
);
export const selectCountries = createSelector(
  selectSession,
  (subState) => subState.countries
);

export const selectStudentTags = createSelector(
  selectSession,
  (subState) => subState.studentTags
);
export const selectPartnerTags = createSelector(
  selectSession,
  (subState) => subState.partnerTags
);
export const selectClientTags = createSelector(
  selectSession,
  (subState) =>subState.clientTags
);
export const selectContactTags = createSelector(
  selectSession,
  (subState) => subState.contactTags
);
export const selectVolunteerTags = createSelector(
  selectSession,
  (subState) => subState.volunteerTags
);
export const selectWorkShadowTags = createSelector(
  selectSession,
  (subState) => subState.workShadowTags
);
export const selectAlumniTags = createSelector(
  selectSession,
  (subState) => subState.alumniTags
);
export const selectEducationTags = createSelector(
  selectSession,
  (subState) => subState.educationTags
);
export const selectFileTags = createSelector(
  selectSession,
  (subState) => subState.fileTags
);
export const selectFileSystemTags = createSelector(
  selectSession,
  (subState) => subState.fileSystemTags
);
export const selectAllFileTags = createSelector(
  selectSession,
  (subState) => [...subState.fileSystemTags, ...subState.fileTags]
);

export const selectGenderPronounsConfig = createSelector(
  selectSession,
  (subState) => subState.genderPronounsConfig
);

export const selectUniversities = createSelector(
  selectSession,
  (subState) => subState.universities
);
export const selectDegreePools = createSelector(
  selectSession,
  (subState) => subState.degreePools
);

export const selectCompanyTypes = createSelector(
  selectSession,
  (subState) => subState.companyTypes
);

export const selectPartnerTypes = createSelector(
  selectSession,
  (subState) => subState.partnerTypes
);

export const selectInstitutionTypes = createSelector(
  selectSession,
  (subState) => subState.institutionTypes
);


export const selectAchievements = createSelector(
  selectSession,
  (subState) => subState.achievements
);

export const selectAtRiskReasons = createSelector(
  selectSession,
  (subState) => subState.atRiskReasonConfigs
);

export const selectInactiveReasons = createSelector(
  selectSession,
  (subState) => subState.inactiveReasonConfigs
)

export const selectStudentTaskActions = createSelector(
  selectSession,
  (subState) => subState.studentTaskActions
);

export const selectHighSchoolProgramTaskActions = createSelector(
  selectSession,
  (subState) => subState.highSchoolProgramTaskActions
);

export const selectPlacementTaskActions = createSelector(
  selectSession,
  (subState) => subState.placementTaskActions
);

export const selectInternshipExperienceFields = createSelector(
  selectSession,
  (subState) => subState.internshipExperienceFields
);

export const selectTeams = createSelector(
  selectSession,
  (subState) => subState.teams
);

export const selectSubTeams = createSelector(
  selectSession,
  (subState) => subState.subTeams
);

export const selectSemesterDateSettings = createSelector(
  selectSession,
  (subState) => subState.semesterDateSeasonSettings
);

export const selectStudentTaskCompletionSource = createSelector(
  selectSession,
  (subState) => subState.completionSourceConfig
);

export const selectScorecardDateSeasonSettings = createSelector(
  selectSession,
  (subState) => subState.scorecardDateSeasonSettings
);

export const selectEmailTemplates = createSelector(
  selectSession,
  (subState) => subState.emailTemplates
);

export const selectEmailTemplateDetail = createSelector(
  selectSession,
  (subState) => {
    let emailTemplate = undefined;
    if (subState.emailTemplates) {
      emailTemplate = subState.emailTemplates.find(x => x.id === subState.selectedTemplateId);
    }
    return emailTemplate;
  }
);

export const selectCurrentSeasonSettings = createSelector(
  selectSession,
  (subState) => subState.currentSeasonSettings
);

export const selectApproachingSeasonSettings = createSelector(
  selectSession,
  (subState) => subState.approachingSeasonSettings
);

export const selectTemplateVariables = createSelector(
  selectSession,
  (subState) => subState.emailTemplateVariables
);

export const selectAllNonPrivateActionTypes = createSelector(
  selectSession,
  (subState) => {
    let result: string[] = [];
    if (subState.actionCategories) {
      result = Object.values(subState.actionCategories).map((value: ActionTypeReferenceModel[]) => value.filter(x => !x.isPrivate && x.actionType).map(x => x.actionType??'')).flat()??[];
    }
    return result;
  }
);

export const selectAllPrivateActionTypes = createSelector(
  selectSession,
  (subState) => {
    let result: string[] = [];
    if (subState.actionCategories) {
      result = Object.values(subState.actionCategories).map((value: ActionTypeReferenceModel[]) => value.filter(x => x.isPrivate && x.actionType).map(x => x.actionType??'')).flat()??[];
    }
    return result;
  }
);

export const selectTemplateFileListResult = createSelector(
  selectSession,
  (subState) => subState.templateFileList
);

export const selectLearningContractConfig = createSelector(
  selectSession,
  (subState) => subState.learningContractConfig
);

export const selectGraduatingCapabilitiesConfig = createSelector(
  selectSession,
  (subState) => subState.graduatingCapabilitiesConfig
);

export const selectPreUniversityGraduatingCapabilitiesConfig = createSelector(
  selectSession,
  (subState) => subState.preUniversityGraduatingCapabilitiesConfig
);

export const selectCareerQuestionnaireConfig = createSelector(
  selectSession,
  (subState) => subState.careerQuestionnaireConfig
);

export const selectStudentTravelLocationsConfig = createSelector(
  selectSession,
  (subState) => subState.studentTravelLocationConfig
);

export const selectCompanyTaskActions = createSelector(
  selectSession,
  (subState) => subState.companyTaskActions
);
